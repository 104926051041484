import { useConversationMatchCount } from '@capturi/api-conversations'
import {
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

export const ConversationsMatchCount: React.FC = () => {
  const { period } = useFilterPeriodContext()
  const segmentState = useFirstPhoneSegmentState()
  const { data: conversationCount } = useConversationMatchCount(
    segmentState.values,
    period,
  )
  if (conversationCount === undefined) return null
  return (
    <Text fontSize="sm">
      <Trans>Conversations: {conversationCount}</Trans>
    </Text>
  )
}
